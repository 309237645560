export const environment = {
  production: false,
  mode: 'dev',
  version: '1.0.0',
  service: {
    host: 'https://apigw-dev.xbaht.com',
  },
  security: {
    encryption: true,
    publicKey: `-----BEGIN PUBLIC KEY-----
    MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHGygQr2vnJwW0giG/Lhri6h0Kog
    W9YLGI+nEq+TvuWGqiTPSwYhCqZRVLUeqBOjiStpJra68g8TepR48Bpykcx0Bz7B
    7z59xH0Wp3KxrL7LcOjOVevVKdLAbNduDgV9ZMRRfo31wqHEhcbjOgPHeis5jFax
    CipVPvf07JcQzJIPAgMBAAE=
    -----END PUBLIC KEY-----`,
  },
  mobile: {
    universal: 'https://app-dev.xbaht.com',
  },
};
