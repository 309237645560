import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';

import {
  ClaimByCriteriaRequestModel,
  ClaimByCriteriaResponseModel,
} from '../../models/claim.model';
import {
  CountryByCriteriaRequestModel,
  CountryByCriteriaResponseModel,
} from '../../models/country.model';
import {
  CurrencyByCriteriaRequestModel,
  CurrencyByCriteriaResponseModel,
} from '../../models/currency.model';
import {
  PayoutMethodByCriteriaRequestModel,
  PayoutMethodByCriteriaResponseModel,
} from '../../models/payout-method.model';
import {
  PartnerByCriteriaRequestModel,
  PartnerByCriteriaResponseModel,
} from '../../models/partner.model';
import {
  ExpressionByCriteriaRequestModel,
  ExpressionByCriteriaResponseModel,
} from '../../models/expression.model';
import {
  PaymentMethodByCriteriaRequestModel,
  PaymentMethodByCriteriaResponseModel,
} from '../../models/payment-method.model';
import {
  MonthlyPaymentByCriteriaRequestModel,
  MonthlyPaymentByCriteriaResponseModel,
} from '../../models/monthly-payment.model';
import {
  OccupationByCriteriaRequestModel,
  OccupationByCriteriaResponseModel,
} from '../../models/occupation.model';
import {
  PurposeByCriteriaRequestModel,
  PurposeByCriteriaResponseModel,
} from '../../models/purpose.model';
import {
  RegularityByCriteriaRequestModel,
  RegularityByCriteriaResponseModel,
} from '../../models/regularity.model';
import {
  RiskLevelByCriteriaRequestModel,
  RiskLevelByCriteriaResponseModel,
} from '../../models/risk-level.model';

import { TransformTool } from '../tools/transform.tool';
import {
  CustomerTagByCriteriaRequestModel,
  CustomerTagByCriteriaResponseModel,
} from '../../models/customer-tag.model';
import {
  BankByCriteriaRequestModel,
  BankByCriteriaResponseModel,
} from '../../models/bank.model';
import {
  BranchByCriteriaRequestModel,
  BranchByCriteriaResponseModel,
} from '../../models/branch.model';

import { ContextKey } from '../constants/context';

@Injectable({
  providedIn: 'root',
})
export class MasterFetchService {
  constructor(private httpClient: HttpClient) {}

  public claim(
    query: ClaimByCriteriaRequestModel
  ): Observable<ClaimByCriteriaResponseModel> {
    return this.httpClient.get<ClaimByCriteriaResponseModel>(
      `${
        Endpoint.Master.Claim.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public country(
    query: CountryByCriteriaRequestModel
  ): Observable<CountryByCriteriaResponseModel> {
    return this.httpClient.get<CountryByCriteriaResponseModel>(
      `${
        Endpoint.Master.Country.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public currency(
    query: CurrencyByCriteriaRequestModel
  ): Observable<CurrencyByCriteriaResponseModel> {
    return this.httpClient.get<CurrencyByCriteriaResponseModel>(
      `${
        Endpoint.Master.Currency.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public payoutMethod(
    query: PayoutMethodByCriteriaRequestModel
  ): Observable<PayoutMethodByCriteriaResponseModel> {
    return this.httpClient.get<PayoutMethodByCriteriaResponseModel>(
      `${
        Endpoint.Master.PayoutMethod.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public partner(
    query: PartnerByCriteriaRequestModel
  ): Observable<PartnerByCriteriaResponseModel> {
    return this.httpClient.get<PartnerByCriteriaResponseModel>(
      `${
        Endpoint.Master.Partner.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public expression(
    query: ExpressionByCriteriaRequestModel
  ): Observable<ExpressionByCriteriaResponseModel> {
    return this.httpClient.get<ExpressionByCriteriaResponseModel>(
      `${
        Endpoint.Master.Expression.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public paymentMethod(
    query: PaymentMethodByCriteriaRequestModel
  ): Observable<PaymentMethodByCriteriaResponseModel> {
    return this.httpClient.get<PaymentMethodByCriteriaResponseModel>(
      `${
        Endpoint.Master.PaymentMethod.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`,
      { context: new HttpContext().set(ContextKey.ApiVersion, '1.1') }
    );
  }

  public monthlyPayment(
    query: MonthlyPaymentByCriteriaRequestModel
  ): Observable<MonthlyPaymentByCriteriaResponseModel> {
    return this.httpClient.get<MonthlyPaymentByCriteriaResponseModel>(
      `${
        Endpoint.Master.MonthlyPayment.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public occupation(
    query: OccupationByCriteriaRequestModel
  ): Observable<OccupationByCriteriaResponseModel> {
    return this.httpClient.get<OccupationByCriteriaResponseModel>(
      `${
        Endpoint.Master.Occupation.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public purpose(
    query: PurposeByCriteriaRequestModel
  ): Observable<PurposeByCriteriaResponseModel> {
    return this.httpClient.get<PurposeByCriteriaResponseModel>(
      `${
        Endpoint.Master.Purpose.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public regularity(
    query: RegularityByCriteriaRequestModel
  ): Observable<RegularityByCriteriaResponseModel> {
    return this.httpClient.get<RegularityByCriteriaResponseModel>(
      `${
        Endpoint.Master.Regularity.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public riskLevel(
    query: RiskLevelByCriteriaRequestModel
  ): Observable<RiskLevelByCriteriaResponseModel> {
    return this.httpClient.get<RiskLevelByCriteriaResponseModel>(
      `${
        Endpoint.Master.RiskLevel.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public customerTag(
    query: CustomerTagByCriteriaRequestModel
  ): Observable<CustomerTagByCriteriaResponseModel> {
    return this.httpClient.get<CustomerTagByCriteriaResponseModel>(
      `${
        Endpoint.Master.CustomerTag.Common
      }${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }

  public bank(
    query: BankByCriteriaRequestModel
  ): Observable<BankByCriteriaResponseModel> {
    return this.httpClient.get<BankByCriteriaResponseModel>(
      `${Endpoint.Master.Bank.Common}${TransformTool.ConvertObjectToQueryString(
        query
      )}`
    );
  }

  public branch(
    id: string,
    query: BranchByCriteriaRequestModel
  ): Observable<BranchByCriteriaResponseModel> {
    return this.httpClient.get<BranchByCriteriaResponseModel>(
      `${Endpoint.Master.Branch.Common.replace(
        '{id}',
        id
      )}${TransformTool.ConvertObjectToQueryString(query)}`
    );
  }
}
