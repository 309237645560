import { environment } from 'environment';

const Prefix = {
  Identity:
    environment.mode !== 'local'
      ? `${environment.service.host}/identity`
      : 'https://localhost:5101',
  Master:
    environment.mode !== 'local'
      ? `${environment.service.host}/master`
      : 'https://localhost:5501',
  Configuration:
    environment.mode !== 'local'
      ? `${environment.service.host}/configuration`
      : 'https://localhost:5601',
  Customer:
    environment.mode !== 'local'
      ? `${environment.service.host}/customer`
      : 'https://localhost:5901',
  Transaction:
    environment.mode !== 'local'
      ? `${environment.service.host}/order`
      : 'https://localhost:6001',
  Log:
    environment.mode !== 'local'
      ? `${environment.service.host}/logger`
      : 'https://localhost:5701',
  IdentityConnect:
    environment.mode !== 'local'
      ? `${environment.service.host}/identity-connect`
      : 'https://localhost:5961',
};

export const Endpoint = {
  Identity: {
    Authentication: {
      SignIn: `${Prefix.Identity}/authentication/signin`,
      AuthenticatorKey: `${Prefix.Identity}/authentication/authenticatorkey`,
      AuthenticatorCode: `${Prefix.Identity}/authentication/authenticatorcode`,
      Verify: `${Prefix.Identity}/authentication/verify`,
      Permission: `${Prefix.Identity}/authentication/permission`,
      Token: `${Prefix.Identity}/authentication/token`,
      ResetPassword: `${Prefix.Identity}/authentication/resetpassword`,
      ForgotPassword: `${Prefix.Identity}/authentication/forgotpassword`,
      SignOut: `${Prefix.Identity}/authentication/signout`,
    },
    User: {
      Common: `${Prefix.Identity}/user`,
      Current: `${Prefix.Identity}/user/current`,
      History: `${Prefix.Identity}/user/history`,
    },
    Role: {
      Common: `${Prefix.Identity}/role`,
      Lookup: `${Prefix.Identity}/role/lookup`,
      History: `${Prefix.Identity}/role/history`,
    },
  },
  Master: {
    Claim: {
      Common: `${Prefix.Master}/claim`,
    },
    Country: {
      Common: `${Prefix.Master}/country`,
    },
    Currency: {
      Common: `${Prefix.Master}/currency`,
    },
    PayoutMethod: {
      Common: `${Prefix.Master}/payoutmethod`,
    },
    Partner: {
      Common: `${Prefix.Master}/partner`,
    },
    Expression: {
      Common: `${Prefix.Master}/expression`,
    },
    PaymentMethod: {
      Common: `${Prefix.Master}/paymentmethod`,
    },
    RiskLevel: {
      Common: `${Prefix.Master}/risklevel`,
    },
    Occupation: {
      Common: `${Prefix.Master}/occupation`,
    },
    MonthlyPayment: {
      Common: `${Prefix.Master}/monthlypayment`,
    },
    Purpose: {
      Common: `${Prefix.Master}/purpose`,
    },
    Regularity: {
      Common: `${Prefix.Master}/regularity`,
    },
    CustomerTag: {
      Common: `${Prefix.Master}/customertag`,
    },
    Bank: {
      Common: `${Prefix.Master}/bank`,
    },
    Branch: {
      Common: `${Prefix.Master}/bank/{id}/branch`,
    },
  },
  Configuration: {
    Country: {
      Common: `${Prefix.Configuration}/country`,
      History: `${Prefix.Configuration}/country/history`,
    },
    ExchangeRate: {
      Common: `${Prefix.Configuration}/exchangerate`,
      History: `${Prefix.Configuration}/exchangerate/history`,
    },
    TransactionFee: {
      Common: `${Prefix.Configuration}/transactionfee`,
      History: `${Prefix.Configuration}/transactionfee/history`,
    },
    SystemLimit: {
      Common: `${Prefix.Configuration}/systemlimit`,
      History: `${Prefix.Configuration}/systemlimit/history`,
    },
    PayoutPartner: {
      Common: `${Prefix.Configuration}/payoutpartner`,
      History: `${Prefix.Configuration}/payoutpartner/history`,
    },
    Campaign: {
      Common: `${Prefix.Configuration}/campaign`,
      History: `${Prefix.Configuration}/campaign/history`,
    },
    Application: {
      Common: `${Prefix.Configuration}/application`,
      History: `${Prefix.Configuration}/application/history`,
    },
    Notification: {
      Common: `${Prefix.Configuration}/notification`,
      History: `${Prefix.Configuration}/notification/history`,
    },
  },
  Transaction: {
    Order: {
      Common: `${Prefix.Transaction}`,
      Orders: `${Prefix.Transaction}/orders`,
      PatIn: `${Prefix.Transaction}/{orderNo}/payins`,
      PatOut: `${Prefix.Transaction}/{orderNo}/payouts`,
      Customer: `${Prefix.Transaction}/customer`,
      Export: `${Prefix.Transaction}/export`,
      Resend: `${Prefix.Transaction}/resend`,
      Verify: `${Prefix.Transaction}/verify`,
      Refund: `${Prefix.Transaction}/refund`,
      History: `${Prefix.Transaction}/history`,
    },
  },
  Customer: {
    Customer: {
      Common: `${Prefix.Customer}/customer`,
      Export: `${Prefix.Customer}/customer/export`,
      History: `${Prefix.Customer}/customer/history`,
    },
    ComplianceRedFlag: {
      Common: `${Prefix.Customer}/complianceredflag`,
      Group: `${Prefix.Customer}/complianceredflag/group`,
    },
    Recipient: {
      Common: `${Prefix.Customer}/recipient`,
      History: `${Prefix.Customer}/recipient/history`,
    },
    DocumentNote: {
      Common: `${Prefix.Customer}/documentnote`,
    },
    DocumentHistory: {
      Common: `${Prefix.Customer}/documenthistory`,
    },
    BFF: {
      CustomerToken: `${Prefix.Customer}/bff/customer/token`,
    },
  },
  Log: {
    History: {
      Common: `${Prefix.Log}/history`,
      Lookup: `${Prefix.Log}/history/lookup`,
    },
  },
  IdentityConnect: {
    IDkollen: {
      Common: `${Prefix.IdentityConnect}/history`,
      Authentication: `${Prefix.IdentityConnect}/idkollen/bankid/authentication`,
      Collect: `${Prefix.IdentityConnect}/idkollen/bankid/collect`,
      Cancel: `${Prefix.IdentityConnect}/idkollen/bankid/cancel`,
    },
  },
};
