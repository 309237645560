import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EMPTY } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
  MASTER_SET_COUNTRY,
  MASTER_SET_CURRENCY,
  MASTER_SET_EXPRESSION,
  MASTER_SET_PARTNER,
  MASTER_SET_PAYOUT_METHOD,
  MASTER_SET_PAYMENT_METHOD,
  MASTER_SET_MONTHLY_PAYMENT,
  MASTER_SET_OCCUPATION,
  MASTER_SET_PURPOSE,
  MASTER_SET_REGULARITY,
  MASTER_SET_RISK_LEVEL,
  MASTER_SET_CUSTOMER_TAG,
} from '../actions/master.action';

@Injectable({
  providedIn: 'root',
})
export class MasterEffect {
  constructor(
    private actions$: Actions,
    private translateService: TranslateService
  ) {}

  private masterSetCountryEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_COUNTRY),
      // tap((value) => console.log(`${MASTER_SET_COUNTRY}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private masterSetCurrencyEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_CURRENCY),
      // tap((value) => console.log(`${MASTER_SET_CURRENCY}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private masterSetPayoutMethodEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_PAYOUT_METHOD),
      // tap((value) => console.log(`${MASTER_SET_PAYOUT_METHOD}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private masterSetPartnerEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_PARTNER),
      // tap((value) => console.log(`${MASTER_SET_PARTNER}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private masterSetExpressionEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_EXPRESSION),
      // tap((value) => console.log(`${MASTER_SET_EXPRESSION}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private masterSetPaymentMethodEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_PAYMENT_METHOD),
      // tap((value) => console.log(`${MASTER_SET_PAYMENT_METHOD}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private masterSetMonthlyPaymentEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_MONTHLY_PAYMENT),
      // tap((value) => console.log(`${MASTER_SET_MONTHLY_PAYMENT}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private masterSetOccupationEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_OCCUPATION),
      // tap((value) => console.log(`${MASTER_SET_OCCUPATION}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private masterSetPurposeEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_PURPOSE),
      // tap((value) => console.log(`${MASTER_SET_PURPOSE}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private masterSetRegularityEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_REGULARITY),
      // tap((value) => console.log(`${MASTER_SET_REGULARITY}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private masterSetRiskLevelEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_RISK_LEVEL),
      // tap((value) => console.log(`${MASTER_SET_RISK_LEVEL}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );

  private masterSetCustomerTagEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(MASTER_SET_CUSTOMER_TAG),
      // tap((value) => console.log(`${MASTER_SET_CUSTOMER_TAG}:`, value)),
      mergeMap(() =>
        EMPTY.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map(() => null as any)
        )
      )
    )
  );
}
